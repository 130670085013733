
import cloneDeep from 'lodash-es/cloneDeep'
import axios from 'axios'

const DEFAULT_FILTER = {
  themes: [],
  participants: [],
  search: '',
  day: '',
}

function participantsOptionsMappingFn(options) {
  if (!options?.length || !Array.isArray(options)) {
    return
  }

  const result = [];
  const sortedOptions = [...options]?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [];

  [...sortedOptions || []].forEach(option => {
    if (!option?.name || !option?.id) {
      return
    }

    const startLetter = (option.name)?.trim()?.[0]?.toLowerCase()
    const group = result.find(item => item.group === startLetter)

    if (group) {
      group.items.push(option)
    } else {
      result.push({
        group: startLetter,
        items: [option]
      })
    }
  })

  return result
}

function themesOptionsMappingFn(options) {
  return [
    {
      caption: '',
      themes: options?.filter(item => item?.name && item?.id) || [],
      title: ''
    }
  ]
}

function withAdditionalTagsProperty(data) {
  if (!data) {
    return
  }

  const mappedData = data
  const tags = []

  if (data?.subjects) {
    tags.push(data.subjects)
  }
  if (data?.tag) {
    tags.push(data.tag)
  }

  return {
    ...mappedData,
    tags
  }
}

export default {
  namespaced: true,

  state: {
    filter: cloneDeep(DEFAULT_FILTER),
    nextPageNumber: 0,
    items: [],
    participantsOptions: [],
    themesOptions: [],
    lang: '',
    useCache: 0,
    detailProgram: null,
    isLoadingDetailProgram: false,
  },

  getters: {
    FILTER(state) {
      return state.filter
    },

    FILTER_DATA(state, getters) {
      const data = {
        lang: getters.LANG,
      }

      if (state.filter.day) {
        data.date = state.filter.day
      } else {
        data.undate = 1
      }
      if (state.filter.search) {
        data.search = state.filter.search
      }
      if (state.filter.themes && state.filter.themes.length) {
        data.subjects = state.filter.themes
      }
      if (state.filter.participants && state.filter.participants.length) {
        data.participants = state.filter.participants
      }

      return data
    },

    NEXT_PAGE_NUMBER(state) {
      return state.nextPageNumber
    },

    ITEMS(state) {
      return state.items.map(item => withAdditionalTagsProperty(item))
    },

    PARTICIPANTS_OPTIONS(state) {
      return state.participantsOptions
    },

    THEMES_OPTIONS(state) {
      return state.themesOptions
    },

    LANG(state) {
      return state.lang
    },

    USE_CACHE(state) {
      return state.useCache
    },

    DETAIL_PROGRAM(state) {
      return withAdditionalTagsProperty(state.detailProgram)
    },

    IS_LOADING_DETAIL_PROGRAM(state) {
      return state.isLoadingDetailProgram
    }
  },

  mutations: {
    SET_FILTER_ITEM(state, { name, value }) {
      state.filter[name] = value
    },

    SET_FILTER(state, { filter, withReplace }) {
      state.filter = withReplace ? filter : { ...state.filter, ...filter }
    },

    CLEAR_FILTER(state) {
      state.filter = cloneDeep(DEFAULT_FILTER)
    },

    SET_ITEMS(state, { items, withReplace }) {
      state.items = withReplace ? items : [ ...state.items, ...items ]
    },

    SET_PARTICIPANTS_OPTIONS(state, options) {
      state.participantsOptions = participantsOptionsMappingFn(options)
    },

    SET_STATE(state, { name, value }) {
      if (!Object.hasOwn(state, name)) {
        return console.warn(`store:program | SET_STATE - свойство ${name} не объявлено в state экземпляре`)
      }

      state[name] = value
    }
  },

  actions: {
    async SEND_FILTER({ commit, getters }, withNextPage) {
      try {
        commit('loading/SET_LOADING', true, { root: true })
        const nextPageNumber = withNextPage ? getters.NEXT_PAGE_NUMBER : 1

        const options = {
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:sessions&action=getList',
          params: {
            ...getters.FILTER_DATA,
            size: 10,
            page: nextPageNumber
          },
        }

        const response = await axios(options);

        if (response.status === 200 || response.statusText === 'OK') {
          const responseNextPage = response.data.data.pagination?.page + 1 <= response.data.data.pagination?.pageCount ? response.data.data.pagination?.page + 1 : 0
          commit('SET_STATE', { name: 'nextPageNumber', value: responseNextPage })
          commit('SET_ITEMS', { items: response.data.data?.items, withReplace: !withNextPage })
        }
      } catch (e) {
        throw new Error(`store: program | SEND_FILTER`, e);
      } finally {
        commit('loading/SET_LOADING', false, { root: true })
      }
    },

    async LOAD_FILTER_OPTIONS({ commit, getters }) {
      try {
        const options = {
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:sessions&action=getFilters',
          params: {
            lang: getters.LANG,
          }
        }

        const response = await axios(options);

        if (response?.data?.status === 'success' && response?.data?.data) {
          commit('SET_PARTICIPANTS_OPTIONS', response.data.data?.participants || [])
          commit('SET_STATE', { name: 'themesOptions', value: themesOptionsMappingFn(response.data.data?.subjects) })
        }
      } catch(e) {
        throw new Error(`store: program | LOAD_FILTER_OPTIONS`, e);
      }
    },

    async LOAD_DETAIL_RPOGRAM({ commit, getters }, id) {
      try {
        commit('SET_STATE', { name: 'isLoadingDetailProgram', value: id })
        const response = await axios({
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:sessions&action=getDetail',
          params: {
            lang: getters.LANG,
            id,
            cache: getters.USE_CACHE
          }
        })

        if (response?.data?.status === 'success') {
          commit('SET_STATE', { name: 'detailProgram', value: response.data.data })
        }
      } catch(e) {
        throw new Error(`store: program | LOAD_DETAIL_RPOGRAM`, e);
      } finally {
        commit('SET_STATE', { name: 'isLoadingDetailProgram', value: false })
      }
    }
  }
}
