<template>
  <div class="business-program">
    <div class="u-container">
      <div class="business-program__inner">
        <h2 class="business-program__title title-2lg">
          {{ title[lang] }}
        </h2>
        <BusinessProgramFilter
          class="business-program__filter"
          :filterParticipants="PARTICIPANTS_OPTIONS"
          :filterThemes="THEMES_OPTIONS"
          :filterDays="mappedFilterDays"
          :lang="lang"
          :notFoundCaption="notFoundLang[lang]"
          @changeFilter="onFilter"
        />
        <BusinessProgramList
          v-if="ITEMS?.length"
          :items="ITEMS"
          :lang="lang"
          :currentProgram="DETAIL_PROGRAM"
          @openProgram="onOpenProgram"
          @closeProgram="onCloseProgram"
        />
        <div v-else class="business-program__not-found">
          {{ notFoundLang[lang] }}
        </div>
        <div ref="infinityTrigger" class="business-program__infinity-trigger" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  props: {
    initialItems: {
      type: Array,
      default: () => []
    },
    initialLang: {
      type: String,
      default: 'ru'
    },
    filterDays: {
      type: Array,
      default: () => []
    },
    actionFilter: {
      type: String,
      default: ''
    },
    initialPagination: {
      type: Object,
      default: () => ({})
    },
    useCache: {
      type: [Number, String],
      default: 1
    },
    showUndated: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      notFoundLang: {
        ru: 'Ничего не найдено',
        en: 'Nothing found'
      },
      intersectionObserver: null,
      title: {
        ru: 'Программа',
        en: 'Program'
      },
      filterDateVoid: {
        ru: 'Дата не назначена',
        en: 'An unassigned date'
      },
      mappedFilterDays: cloneDeep(this.filterDays)
    }
  },

  computed: {
    ...mapGetters('program', ['ITEMS', 'NEXT_PAGE_NUMBER', 'PARTICIPANTS_OPTIONS', 'THEMES_OPTIONS', 'DETAIL_PROGRAM']),
    lang() {
      return this.initialLang?.toLowerCase()
    },
    initialNextPageNumber() {
      return this.initialPagination?.page + 1 <= this.initialPagination?.pageCount ? this.initialPagination?.page + 1 : 0
    }
  },

  methods: {
    ...mapMutations('program', ['SET_ITEMS', 'SET_FILTER_ITEM', 'SET_STATE']),
    ...mapActions('program', ['SEND_FILTER', 'LOAD_FILTER_OPTIONS', 'LOAD_DETAIL_RPOGRAM']),

    onFilter() {
      this.SEND_FILTER(false)
    },

    async observerHandler(entries) {
      if (!entries[0].isIntersecting || this.NEXT_PAGE_NUMBER <= 1) {
        return
      }

      this.SEND_FILTER(true);
    },

    setDefaultFilterDay() {
      const day = this.mappedFilterDays?.find(item => item?.initial)?.date || this.mappedFilterDays?.[0]?.date || ''
      this.SET_FILTER_ITEM({ name: 'day', value: day })
    },

    async init() {
      this.mappedFilterDays = this.filterDays.map((item, i) => ({ date: item.date, initial: item.initial, name: item.format, id: i + 1 }))

      if (this.showUndated) {
        this.mappedFilterDays = [{ date: '', name: this.filterDateVoid[this.lang], id: 'undated' }, ...this.mappedFilterDays]
      }

      this.setDefaultFilterDay()
      this.SET_STATE({ name: 'lang', value: this.initialLang })
      this.SET_STATE({ name: 'useCache', value: this.useCache })

      await this.LOAD_FILTER_OPTIONS()

      this.SET_ITEMS({ items: this.initialItems })
      this.SET_STATE({ name: 'nextPageNumber', value: this.initialNextPageNumber })
    },

    onOpenProgram(id) {
      this.LOAD_DETAIL_RPOGRAM(id)
    },

    onCloseProgram() {
      this.SET_STATE({ name: 'detailProgram', value: null })
    }
  },

  mounted() {
    this.intersectionObserver = new IntersectionObserver(this.observerHandler);
    this.intersectionObserver.observe(this.$refs.infinityTrigger);
  },

  created() {
    this.init()
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-program';

#{$b} {

  // .business-program__title
  &__title {
    margin-bottom: 40px;

    @include tablet {
      margin-bottom: 25px;
    }
  }

  // .business-program__filter
  &__filter {
    margin-bottom: 30px;
  }

  // .business-program__infinity-trigger
  &__infinity-trigger {
    height: 1px;
    opacity: 0;
  }
}
</style>
